<template>
    <div class="addressInfo">
        <el-form class="search" style="display: flex !important">
            <el-form-item  label-width="90px" label="批次号">
                <el-input v-model:value="batch_id"/>
            </el-form-item>&nbsp;&nbsp;&nbsp;
            <el-button type="primary" icon="el-icon-search" @click="getTableData(1)">搜索</el-button>&nbsp;&nbsp;&nbsp;
            <el-button type="" icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form>
        <div class="infoBox">
            <div class="table-data">
                <TableLoading :loadShow="loadShow"></TableLoading>
                <el-table :data="tableData" border>
                    <el-table-column fixed prop="index" label="序号" width="70"></el-table-column>
                    <!-- <el-table-column prop="logo_img" label="logo">
                        <template slot-scope="{ row }">
                            <img :src="row.logo_img" style="width: 80px; height: 80px;">
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="name" label="贴牌商"/>
                    <el-table-column prop="batch_id" label="批次号" width="90" />
                    <el-table-column prop="total_price" label="结算金额"/>
                    <!-- <el-table-column prop="percentage" label="占比"/> -->
                    <el-table-column prop="open_name" label="开户名"/>
                    <el-table-column prop="bankId" label="银行卡号"/>
                    <el-table-column prop="bank_type" label="卡类型"/>
                    <el-table-column prop="open_bank" label="开户行"/>
                    <el-table-column prop="order_type" label="订单类型">
                        <template slot-scope="{ row }">
                            <el-tag>{{row.order_type==1?'自销订单':'外部订单'}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="结算状态">
                        <template slot-scope="{ row }">
                            <el-tag :type="row.status==1?'':row.status==2?'success':'error'">{{row.status==1?'待结算':row.status==2?'结算成功':'结算失败'}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="err_msg" label="失败原因">
                        <template slot-scope="{ row }">
                            <div v-if="row.err_msg!= ''">{{row.err_msg}}</div>
                            <div v-else style="height: 2px; width: 21px; background-color: plum;"></div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="InfoMsg" label="详情">
                        <template slot-scope="{ row }">
                            <el-tag style="cursor: pointer;" @click="viewInfoMsg(row.batch_id)">查看</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="创建时间"/>
                    <el-table-column fixed="right" label="操作" width="165">
                        <template slot-scope="{ row }">
                            <el-button type="primary" size="small" @click="examin(row.batch_id)" v-if="row.status!=2">审核</el-button>
                            <div v-else style="height: 2px; width: 21px; background-color: plum;"></div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="pageBox">
                <el-pagination background layout="total, sizes, prev, pager, next, jumper"
                    @current-change="handleCurrentChange" :current-page="pageNum"
                    :page-sizes="[10]" :page-size="pageSize" :total="total">
                </el-pagination>
            </div>
        </div>

        <el-dialog title="结算详情" :visible.sync="setteledInfoVisible" width="1400px">
            <div class="infoBox">
                <div class="table-data">
                    <TableLoading :loadShow="loadShow"></TableLoading>
                    <el-table :data="detailInfo.tableData" border>
                        <el-table-column fixed prop="index" label="序号" width="70"></el-table-column>
                        <el-table-column prop="order_id" label="订单号"/>
                        <el-table-column prop="real_price" label="订单收款" />
                        <el-table-column prop="money" label="所得金额"  />
                        <el-table-column prop="percentage" label="占比" />
                        <el-table-column prop="order_type" label="订单类型">
                            <template slot-scope="{ row }">
                                <el-tag>{{row.order_type==1?'自销订单':'外部订单'}}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="status" label="结算状态">
                            <template slot-scope="{ row }">
                                <el-tag :type="row.status==1?'':row.status==2?'success':row.status==3?'':'error'">{{row.status==1?'未结算':row.status==2?'已结算':row.status==3?'结算审核中':'结算失败'}}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="err_msg" label="失败原因"/>
                        <el-table-column prop="create_time" label="创建时间" />
                    </el-table>
                </div>
                <div class="pageBox">
                    <el-pagination background layout="total, sizes, prev, pager, next, jumper"
                        @current-change="handleCurrentChangeDetial" :current-page="detailInfo.pageNum"
                        :page-sizes="[10]" :page-size="pageSize" :total="detailInfo.total">
                    </el-pagination>
                </div>
            </div>
        </el-dialog>
        <!-- 审核弹窗 -->
        <el-dialog title="审核" :visible.sync="examinVisible" width="400px">
            <el-form>
                <el-form-item label="审核结果">
                    <el-radio-group v-model="radio">
                        <el-radio :label="2">通过</el-radio>
                        <el-radio :label="3">拒绝</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="拒绝原因" v-if="radio == 3">
                    <el-input placeholder="请输入拒绝原因" v-model="refuseText" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="examinVisible = false">取 消</el-button>&nbsp;&nbsp;
                <el-button type="primary" :loading="btnLoading" @click="updateStatus">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { SupGetOrderSettledInfo, examinBatchSettelApi, getSettledBatchDetail  } from "@/common/api";
import TableLoading from "@/components/index/TableLoading.vue";
import StatusTab from "@/components/index/StatusTab.vue";

export default {
    data() {
        return {
            loadShow: false,
            pageNum: 1, // 当前页数
            total: 0, // 总数据量
            pageSize: 10, // 每页显示条数
            tableData: [],
            batch_id: '',   //批次号
            examinVisible: false,
            radio: 2,
            refuseText: '',
            btnLoading: false,
            setteledInfoVisible: false,
            detailInfo: {
                tableData: [],
                total: 0,
                pageNum: 1,
                batch_id: '',
            },
        };
    },
    components: {
        TableLoading,
        StatusTab,
    },
    mounted() {
        this.getTableData(1)
    },
    methods: {
        // 重置
        reset() {
            this.batch_id = "";
            this.pageNum = 1;
            this.getTableData();
        },
        // 当前页改变时
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getTableData();
        },
        // 当前页改变时
        handleCurrentChangeDetial(val) {
            this.detailInfo.pageNum = val;
            this.viewInfoMsg(this.detailInfo.batch_id);
        },
        // 获取表格数据
        async getTableData(page) {
            if (page) this.pageNum = page;
            this.loadShow = true;
            let tableData = await SupGetOrderSettledInfo({
                batch_id: this.batch_id,
                page: this.pageNum,
            });
            this.loadShow = false;
            if (!tableData) return;
            tableData.data.forEach((element, index) => {
                element.index = index + 1 + (this.pageNum - 1) * 10;
            });
            this.tableData = tableData.data;
            this.total = tableData.total;
        },
        // 审核
        async examin(batch_id){
            this.batch_id = batch_id
            this.examinVisible = true
        },
        async updateStatus(){
            this.btnLoading = true
            let res = await examinBatchSettelApi({
                batch_id: this.batch_id,
                status: this.radio,
                refuseText: this.refuseText,
            })
            this.btnLoading = false
            if(!res) return
            this.getTableData()
            this.examinVisible = false
        },
        // 查看批次号详情
        async viewInfoMsg(batch_id){
            this.detailInfo.batch_id = batch_id
            let res = await getSettledBatchDetail({
                batch_id: this.detailInfo.batch_id,
                page: this.detailInfo.pageNum
            })
            if(!res) return
            res.data.forEach((element, index) => {
                element.index = index + 1 + (this.detailInfo.pageNum - 1) * 10;
            });
            this.detailInfo.tableData = res.data
            this.detailInfo.total = res.total;
            this.setteledInfoVisible = true
        },
    },
};
</script>

<style lang="less" scoped>

</style>
